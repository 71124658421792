import React from 'react';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  TwoSectionsWithLeftHiddenOnMobile,
} from '../page.style';
import ContactForm from "../SaasMinimal2/ContactForm";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from 'common/components/GatsbyImage';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      woman: file(relativePath: { eq: "image/saasMinimal2/woman.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="kontaktiere uns"/>
            <TwoSectionsWithLeftHiddenOnMobile>
              <ContactForm/>
              <Illustration>
                <GatsbyImage
                  src={
                    (data.woman !== null) | undefined
                      ? data.woman.childImageSharp.gatsbyImageData
                      : {}
                  }
                  alt="contact-us"
                />
              </Illustration>
            </TwoSectionsWithLeftHiddenOnMobile>
          </TextContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};